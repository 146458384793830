import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addtoCart,
  decreaseCart,
  getSubtotal,
  removeAllFromCart,
  removeFromCart,
  // removeFromCart,
  // removeFromCart,
} from "../features/products/cartSlice";

import { currencyFormatter } from "../utils/currencyFormatter";
import { useNavigate } from "react-router-dom";
// import { RiDeleteBin6Line } from "react-icons/ri";
import { Spinner } from "@material-tailwind/react";
import { toast } from "react-toastify";
import { shippingFetching } from "../features/shipping/shippingChargeSlice";

const Checkout = () => {
  const navigate = useNavigate();
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );
  const { items: shippingCharge } = useSelector(
    (state) => state.shippingCharge
  );

  const [orderItem] = useState(data);
  const [isLoading, setIsLoading] = useState(false);

  const [response] = useState(0);

  // console.log(JSON.stringify(orderItem).split(" "));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubtotal());
  }, [data, dispatch]);

  useEffect(() => {
    dispatch(shippingFetching());
  }, [dispatch]);
  // const navigate = useNavigate();

  const [selectedShipping, setSelectedShipping] = useState(shippingCharge[0]);

  useEffect(() => {
    setSelectedShipping(shippingCharge[0]);
  }, [shippingCharge]);

  const handleDeliveryChange = (event) => {
    const selectedId = parseInt(event.target.value, 10);

    const selectedCharge = shippingCharge.find(
      (charge) => charge.amount === selectedId
    );
    setSelectedShipping(selectedCharge);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    shipping_cost: "",
    shipping_type: "Cash On Delivery",
  });
  // const [couponCode, setCouponCode] = useState("");

  // const handleCouponCodeChange = (event) => {
  //   setCouponCode(event.target.value);
  // };

  const handleDecrease = (product) => {
    if (product?.cartQuantity > 1) {
      dispatch(decreaseCart(product));
    } else {
      dispatch(removeFromCart(product));
    }
  };

  const handleIncrease = (product) => {
    dispatch(addtoCart(product));
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  // const handleRemove = (product) => {
  //   dispatch(removeFromCart(product));
  // };
  // const applyCoupon = async () => {
  //   setCouponCode("");
  //   try {
  //     await fetch(`${process.env.REACT_APP_URL}/api-coupon-code`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         coupon: couponCode,
  //       }),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setResponse(data);
  //       });
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };
  let grand_total = response.discount
    ? cartTotalAmount + selectedShipping?.amount + -response.discount
    : cartTotalAmount + selectedShipping?.amount + 0;

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (formData.shipping_type === "Cash On Delivery") {
      try {
        setIsLoading(true);
        const res = await fetch(`${process.env.REACT_APP_URL}/place-order`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.name,
            // email: formData.email,
            phone: formData.phone,
            address: formData.address,
            item: orderItem,
            shipping_cost: selectedShipping.amount,
            // customize_cost: option.value,
            shipping_type: formData.shipping_type,
            grand_total,
            // product_type: option.label,
          }),
        });

        if (!res.ok) {
          toast.info("Quantity Increased", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        if (res.ok) {
          setIsLoading(false);
          dispatch(removeAllFromCart());
          navigate("/successpage");
        }
      } catch (err) {
        setIsLoading(false);
        console.log(err.message);
      }
    }

    // if (formData.shipping_type === "SSL Commerz") {
    //   const queryParams = new URLSearchParams({
    //     name: formData.name,
    //     item: JSON.stringify(orderItem),
    //     // email: formData.email,
    //     phone: formData.phone,
    //     address: formData.address,
    //     grand_total,
    //     shipping_type: formData.shipping_type,
    //     shipping_cost: formData.shipping_cost,
    //   });

    //   window.location.href = `${process.env.REACT_APP_PAYMENT_URL}/?${queryParams}`;
    // }
  };

  // function setCustomValidity(textbox) {
  //   if () {
  //     textbox.setCustomValidity("must give 11 word");
  //   } else {
  //     textbox.setCustomValidity("");
  //   }
  //   return true;
  // }

  // const numberText = document.getElementById("number");

  // if (numberText) {
  //   const numberTow = parseInt(numberText);

  //   alert(numberTow);
  // }
  // if (document.getElementById("number").leanth) {
  //   //Male radio button is checked
  // } else if (document.getElementById("gender_Female").checked) {
  //   //Female radio button is checked
  // }

  return (
    <div className="relative w-full h-full pt-20 ">
      <div className="container  mx-auto mt-20 px-2">
        <div className="grid md:grid-cols-9 gap-10">
          <div className="product-details md:col-span-5 lg:col-span-6">
            <div>
              <div className="product-headlines grid grid-cols-5 gap-10 border-b pb-3 uppercase font-medium">
                <div className="col-product col-span-2">Product</div>
                <div className="col-span-3  hidden lg:block">
                  <div className="flex justify-between">
                    <div className="">Price</div>
                    <div className="">Quantity</div>
                    <div className="">Total</div>
                  </div>
                </div>
              </div>

              {data.map((product) => (
                <div
                  key={product.id}
                  className="products grid grid-cols-5 gap-10 border-b pb-3 uppercase font-medium px-2"
                >
                  <div className="col-product col-span-5 lg:col-span-2 flex gap-3 items-center">
                    <img
                      src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
                      alt="leptop"
                      className="w-28 h-28 object-cover"
                      onClick={() => handleImageClick(product?.photos)}
                    />

                    <div className="name">
                      {product?.name?.substring(0, 60)}...
                    </div>
                  </div>
                  <div className="flex gap-4 md:gap-7 col-span-3 items-center justify-between">
                    <div className="col-unit-price">
                      {currencyFormatter(+product?.variantPrice)}
                    </div>
                    <div className="">
                      <div className="counter flex">
                        <button
                          onClick={() => handleDecrease(product)}
                          className="h-8 cursor-pointer w-8 md:h-10 md:w-10 bg-gray-100  border border-gray-300 active:bg-gray-700 active:text-gray-50"
                        >
                          -
                        </button>
                        <span className="h-8 w-8 md:h-10 md:w-10 bg-gray-100 flex justify-center items-center border border-gray-300">
                          {product?.cartQuantity}
                        </span>
                        <button
                          onClick={() => handleIncrease(product)}
                          className="h-8 w-8 md:h-10 md:w-10 bg-gray-100 border border-gray-300 active:bg-gray-700 active:text-gray-50"
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="">
                      {currencyFormatter(+product?.variantPrice)}
                    </div>
                    {/* <div className="delete hover:text-rose-500 duration-300">
                    <RiDeleteBin6Line onClick={() => handleRemove(product)} />
                  </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="customer-details md:col-span-4 lg:col-span-3 ">
            {/* <div className="helping-button flex items-center justify-center mt-5 gap-5 mb-5">
              <a
                href="tel://+8801745756713"
                className="w-full py-3 px-2 text-[12px] md:text-sm bg-black  rounded-md duration-300 flex flex-col items-center justify-center text-white"
              >
                অর্ডার করতে কল করুন
              </a>
              <a
                href="tel://+8801745756713"
                className="w-full py-3 px-2 text-[12px] md:text-sm bg-black  rounded-md duration-300  flex flex-col items-center justify-center  text-white"
              >
                অর্ডার করতে ম্যাসেজ করুন
              </a>
            </div> */}
            <form onSubmit={handleFormSubmit} className="flex flex-col gap-10">
              <div className="border-4 border-[#2658aa]">
                <div className="flex items-center justify-center">
                  <div className="text-black text-center p-10">
                    অর্ডার কনফার্ম করতে আপনার নাম, মোবাইল নাম্বর,ঠিকানা লিখে "
                    অর্ডার করুন " বাটনে ক্লিক করুন।
                  </div>
                </div>

                <div className="flex flex-col gap-2 px-10">
                  <div className="form-control flex flex-col mb-3">
                    <label className="mb-2">নাম</label>
                    <input
                      required
                      type="text"
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                      value={formData.name}
                      className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
                      placeholder="Enter Your First Name"
                    />
                  </div>
                  <div className="form-control flex flex-col   mb-3">
                    <label className="mb-2">মোবাইল নাম্বার</label>
                    <input
                      required
                      type="tel"
                      pattern="[0-9]{11}"
                      id="number"
                      onChange={(e) =>
                        setFormData({ ...formData, phone: e.target.value })
                      }
                      // onChange={handleChange}
                      value={formData.phone}
                      className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300"
                      placeholder="Enter Your Number"
                    />
                    <mat-error></mat-error>
                  </div>
                  <div className="form-control flex flex-col mb-3">
                    <label className="mb-2">ঠিকানা</label>
                    <input
                      required
                      type="text"
                      onChange={(e) =>
                        setFormData({ ...formData, address: e.target.value })
                      }
                      value={formData.address}
                      className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300 resize-none"
                      placeholder="Enter Your Address"
                    />
                  </div>

                  <div className="form-control flex flex-col mb-3">
                    <label>শিপিং পদ্ধতি</label>
                    <select
                      onChange={handleDeliveryChange}
                      className="px-3 py-2  rounded-md outline-none border focus:border-[#EF6536] duration-300"
                      required
                    >
                      {shippingCharge?.map((charge) => (
                        <option key={charge.id} value={`${charge?.amount}`}>
                          {charge?.name}({charge?.amount})
                        </option>
                      ))}
                      {/* <option value="100">Dhaka Subcity(100)</option>
                      <option value="120">Outside Dhaka(120)</option> */}
                    </select>
                  </div>
                  {/* <div className="form-control flex flex-col mb-3">
                    <label>নাম ও মোবাইল নাম্বার লিখে নিতে চান?</label>
                    <select
                      required
                      onChange={setSelectedOption}
                      className="px-3 py-3 mb-4  rounded-md outline-none border focus:border-[#EF6536] duration-300"
                    >
                      {selectOptions?.map((option) => (
                        <option key={selectOptions.length} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div> */}
                </div>
              </div>

              <div className="flex items-center, justify-center">
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`${
                    isLoading
                      ? " bg-gray-600 "
                      : "bg-[#2658aa] hover:bg-[#457dd8]"
                  } px-3 py-3 rounded-md text-lg text-white bg   duration-300`}
                >
                  {isLoading ? <Spinner /> : "অর্ডার কনফার্ম করুন।"}
                </button>
              </div>

              <div className="border-4 border-[#2658aa] flex flex-col gap-5 px-10 pb-10">
                <div className="flex items-center justify-center mt-5">
                  <p className="font-semibold text-xl text-black">
                    Order-summary
                  </p>
                </div>

                <div className="flex flex-col gap-3">
                  <div className="flex justify-between">
                    <span className="text-[#2658aa] text-2xl">Subtotal:</span>
                    <span>
                      <span>৳</span>
                      <span>{currencyFormatter(+cartTotalAmount)}</span>
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-[#2658aa] text-2xl">
                      Delivery Charge:
                    </span>
                    <span> {currencyFormatter(selectedShipping?.amount)}</span>
                  </div>
                </div>
                <div className="bg-[#2658aa] h-[0.10rem]"></div>
                <div>
                  <div className="flex justify-between mb-10">
                    <span className="text-[#2658aa] text-2xl capitalize">
                      total
                    </span>
                    <span>
                      <span>৳</span>
                      <span>{currencyFormatter(grand_total)}</span>
                    </span>
                  </div>
                </div>

                <div className="form-control">
                  <div className="flex justify-center">
                    {/* <button
                    type="submit"
                    className="bg-[#16995D] text-white hover:bg-green-600 px-6 py-4 rounded-md duration-300"
                  >
                    অর্ডার করুন।
                  </button> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {selectedImage && (
          <div className="w-full">
            <div className="modal absolute md:top-[30%] top-[20%] left-[50%] -translate-x-1/2  -translate-y-1/2 md:p-0 p-4 ">
              <div className="modal-content relative">
                <span
                  className="close absolute top-0 right-2.5 text-2xl font-bold cursor-pointer"
                  onClick={handleCloseModal}
                >
                  &times;
                </span>
                <div className="w-[20rem] ">
                  <img
                    src={`${process.env.REACT_APP_URL}/uploads/product/${selectedImage}`}
                    alt="Selected"
                    className="w-full h-full"
                    onClick={handleCloseModal}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Checkout;
